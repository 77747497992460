import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TagDto } from '@ay-gosu/server-shared';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { LegacyAppearanceDirective } from '../../../material/legacy/mat-form-field/legacy-appearance.directive';
import { MatFlatTreePicker } from '../../../material/tree-picker';
import { MatFlatTreePicker as MatFlatTreePicker_1 } from '../../../material/tree-picker/flat-tree-picker';
import {
  MatTreePickerNode,
  MatTreePickerNodeDef,
} from '../../../material/tree-picker/tree-picker-node';
import { TagService } from '../../../service/tag.service';

export type TagTargetType = 'profile' | 'package';

@Component({
  selector: 'dl-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    NgIf,
    MatIcon,
    MatSuffix,
    MatInput,
    FormsModule,
    MatFlatTreePicker_1,
    MatTreePickerNodeDef,
    MatTreePickerNode,
    MatCheckbox,
    MatDivider,
    MatButton,
    LegacyAppearanceDirective,
  ],
})
export class AddTagComponent implements OnDestroy {
  //#region @Two-way() keyword
  private _keyword: string = '';

  private onlyOne: boolean = false;

  public keyword$ = new BehaviorSubject<string>('');

  public get keyword(): string {
    return this._keyword;
  }

  public get onlyOneTag(): boolean {
    return this.onlyOne;
  }

  @Input()
  public set keyword(keyword: string) {
    if (this._keyword === keyword) return;
    this._keyword = keyword;
    this.keyword$.next(this._keyword);
  }

  @Input()
  public set onlyOneTag(onlyOne: boolean) {
    this.onlyOne = onlyOne;
  }
  //#endregion @Two-way() keyword

  @Input()
  public id: number;

  //#region @Input() targetType
  private _targetType: TagTargetType = 'profile';

  public targetType$ = new BehaviorSubject<TagTargetType>('profile');

  @Output()
  public createTag = new EventEmitter();

  public get targetType(): TagTargetType {
    return this._targetType;
  }

  @Input()
  public set targetType(targetType: TagTargetType) {
    if (this._targetType === targetType) return;
    this._targetType = targetType;
    this.targetType$.next(targetType);
  }
  //#endregion @Two-way() targetType

  public tags$ = combineLatest(this.keyword$, this.targetType$).pipe(
    switchMap(([keyword, targetType]) => {
      if (keyword === '') {
        return this._tagService.getTagTreeByType(targetType);
      }

      return this._tagService.getTagListByType(targetType).pipe(
        map((tags) =>
          tags.filter((tag) => {
            try {
              return tag.name.includes(keyword);
            } catch (err) {
              console.error('Error filter tag:', { err, tag });
            }
            return false;
          }),
        ),
      );
    }),
    map((tags) => this._tagService.toTreePickerNodes(tags)),
    shareReplay(1),
  );

  public selected: TagDto[] = [];

  @ViewChild(MatFlatTreePicker, { static: false })
  public treePickerTrigger: MatFlatTreePicker<any>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(private readonly _tagService: TagService) {}

  public ngOnDestroy() {
    const deletedTags = this.selected.filter((tag) => tag.deletedAt);
    if (deletedTags.length) {
      deletedTags.forEach((tag) => {
        let index = this.selected.findIndex((_tag) => tag.id === _tag.id);
        this.selected.splice(index, 1);
      });
    }
    this.treePickerTrigger.closePanel();

    this._destroy$.next();
    this._destroy$.complete();
  }

  public selectTag(tag: TagDto) {
    let index = this.selected.findIndex((_tag) => tag.id === _tag.id);
    if (this.onlyOne) {
      this.selected.push(tag);
      this.selected.splice(0, 1);
    } else {
      if (index === -1) {
        this.selected.push(tag);
      } else {
        this.selected.splice(index, 1);
      }
    }
  }

  public exists(tag: TagDto) {
    return -1 !== this.selected.findIndex((_tag) => tag.id === _tag.id);
  }
}
