// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { FetchReportResponse } from './dto/fetch-report-response.dto';

export class CustomPrivateMessageModel {
  static accountStart(profileId: number): Promise<any> {
    return wsc.execute(
      '/ws/custom-private-message/accountStart',
      profileId,
    ) as any;
  }

  static accountClose(profileId: number): Promise<any> {
    return wsc.execute(
      '/ws/custom-private-message/accountClose',
      profileId,
    ) as any;
  }

  static fetchAccountProfiles(
    profileId: number,
  ): Promise<{ accountId: number; accountName: string }[]> {
    return wsc.execute(
      '/ws/custom-private-message/fetchAccountProfiles',
      profileId,
    ) as any;
  }

  static addAccountProfiles(
    profileId: number,
    accountIds: number[],
  ): Promise<{ accountId: number; accountName: string }[]> {
    return wsc.execute(
      '/ws/custom-private-message/addAccountProfiles',
      profileId,
      accountIds,
    ) as any;
  }

  static fetchReport(
    botId: number,
    start: Date,
    end: Date,
    paging: {
      page: number;
      size: number;
    },
  ): Promise<FetchReportResponse> {
    return wsc.execute(
      '/ws/custom-private-message/fetchReport',
      botId,
      start,
      end,
      paging,
    ) as any;
  }

  static checkProfileChatting(profileId: number): Promise<boolean> {
    return wsc.execute(
      '/ws/custom-private-message/checkProfileChatting',
      profileId,
    ) as any;
  }

  static fetchProfileChatAccountIds(profileId: number): Promise<number[]> {
    return wsc.execute(
      '/ws/custom-private-message/fetchProfileChatAccountIds',
      profileId,
    ) as any;
  }

  static checkProfileBelongsToAccount(profileId: number): Promise<boolean> {
    return wsc.execute(
      '/ws/custom-private-message/checkProfileBelongsToAccount',
      profileId,
    ) as any;
  }

  static subscribeInChat(): Observable<{
    companyId: number;
    accountId: number;
    profileId: number;
    inChat: boolean;
    closedBy?: 'USER' | 'STAFF';
  }> {
    return wsc.subscribe('/ws/custom-private-message/subscribeInChat') as any;
  }
}
// bbff2de9b98ee14fb6c9bf5a617eef11b9b1befc44af9d856be452d9e46d0396
