import {
  Directive,
  EffectRef,
  EmbeddedViewRef,
  Injector,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  effect,
} from '@angular/core';
import { AsyncJob, AsyncJobStatusEnum } from './async-job';

/**
 * 在開發過程中很容易遇非同步的任務，需要處理載入中、載入完成、載入失敗、或者資料是空的狀態， AsyncJob 的就是用來解決此問題的一個解決方案。
 *
 * 收先需要在 TypeScript 中透過 RxJS 的 pipe 機制建立 AsyncJob 的物件，例如:
 * ```typescript
 *  public a = signal(0);
 *  public b = signal(0);
 *  public data$ = asyncJob(async (a, b) => {
 *    // call apis or do something
 *  }, this.a, this.b);
 *  public async executeAsyncFunction(params: string) {
 *    return data;
 *  }
 * ```
 *
 * 然後再 Template 中，使用 asyncJob 指令，例如:
 * ```html
 * <div *asyncJob="data$; let data; loading: loadingBlock; error: errorBlock; empty: emptyBlock">
 *    資料載入完成 {{ data }}
 * </div>
 * <ng-template #loadingBlock>資料載入中...</ng-template>
 * <ng-template #emptyBlock>資料是空的...</ng-template>
 * <ng-template #errorBlock>資料載入異常...</ng-template>
 * ```
 *
 * 你也可以在最外層定義預設的 loading / error / empty 的狀態，例如:
 * ```html
 * <div *asyncJobDefaultLoading class="loading">資料讀取中...</div>
 * <div *asyncJobDefaultEmpty class="empty">資料是空的...</div>
 * ```
 */

const { LOADING, SUCCESS, EMPTY, ERROR } = AsyncJobStatusEnum;

export type AsyncJobValue = {
  status: AsyncJobStatusEnum;
  data: any;
  error: any;
};

@Directive({
  selector: '[asyncJob]',
})
export class AsyncJobDirective<T> implements OnDestroy {
  public static instances: AsyncJobDirective<any>[] = [];
  public static defaultLoading: TemplateRef<any>;
  public static defaultEmpty: TemplateRef<any>;
  public static defaultError: TemplateRef<any>;
  // 為了讓 Angular 可以識別 TemplateRef 的型別，必須要加上這個參數，
  // 但實際上不會使用到這個參數。
  @Input()
  public asyncJobAs!: AsyncJob<T>;

  public loading: TemplateRef<any> | null = null;
  public empty: TemplateRef<any> | null = null;
  public error: TemplateRef<any> | null = null;

  private _latestEmbeddedViewRef: EmbeddedViewRef<any> | undefined;

  private _latestEffectRef: EffectRef | undefined;

  private _latestAsyncJobValue: AsyncJobValue = {
    status: undefined as any,
    data: undefined as any,
    error: undefined as any,
  };

  private _asyncJob: AsyncJob<T> | undefined = undefined;

  public constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _injector: Injector,
    private readonly _templateRef: TemplateRef<{ $implicit: T }>,
  ) {
    AsyncJobDirective.instances.push(this);
  }

  public get asyncJob(): AsyncJob<T> {
    return this._asyncJob!;
  }

  @Input({ required: true })
  public set asyncJob(asyncJob: AsyncJob<T>) {
    if (this._asyncJob === asyncJob) return;

    this._asyncJob = asyncJob;

    this._latestEffectRef?.destroy();
    this._latestEffectRef = this._effect(asyncJob);
  }

  @Input()
  public set asyncJobLoading(templateRef: TemplateRef<any> | null) {
    this.loading = templateRef;
    this.updateTemplateRef(LOADING);
  }

  @Input()
  public set asyncJobEmpty(templateRef: TemplateRef<any> | null) {
    this.empty = templateRef;
    this.updateTemplateRef(EMPTY);
  }

  @Input()
  public set asyncJobError(templateRef: TemplateRef<any> | null) {
    this.error = templateRef;
    this.asyncJob.errorHandler = templateRef;
    this.updateTemplateRef(ERROR);
  }

  // https://angular.io/guide/structural-directives#typing-the-directives-context
  public static ngTemplateContextGuard<T>(
    directive: AsyncJobDirective<T>,
    context: unknown,
  ): context is AsyncJobContext<T> {
    return true;
  }

  public static updateTemplateRef(status: AsyncJobStatusEnum): void {
    AsyncJobDirective.instances.forEach((instance) =>
      instance.updateTemplateRef(status),
    );
  }

  public ngOnDestroy(): void {
    this._destroyLatestEmbeddedViewRef();
    this._latestEffectRef?.destroy();

    const index = AsyncJobDirective.instances.indexOf(this);
    if (index > -1) {
      AsyncJobDirective.instances.splice(index, 1);
    }
  }

  public updateTemplateRef(status: AsyncJobStatusEnum): void {
    if (status !== this._latestAsyncJobValue.status) return;
    const latest = this._latestAsyncJobValue;
    this._updateEmbedded(latest, latest, true);
  }

  private _effect(asyncJob: AsyncJob<T>): EffectRef {
    return effect(
      () => {
        const status = asyncJob.status();
        const data = asyncJob.data();
        const error = asyncJob.error();

        const asyncJobValue: AsyncJobValue = { status, data, error };
        if (this._isNotUpdated(asyncJobValue)) {
          return;
        }

        let latest = this._latestAsyncJobValue;
        this._latestAsyncJobValue = asyncJobValue;
        setTimeout(() => this._updateEmbedded(latest, asyncJobValue));
      },
      { allowSignalWrites: true, injector: this._injector },
    );
  }

  private _updateEmbedded(
    latest: AsyncJobValue,
    current: AsyncJobValue,
    force: boolean = false,
  ) {
    if (this._latestEmbeddedViewRef) {
      if (current.status === latest.status && !force) {
        switch (current.status) {
          case SUCCESS:
            this._latestEmbeddedViewRef.context.$implicit = current.data;
            break;

          case ERROR:
            this._latestEmbeddedViewRef.context.$implicit = current.error;
            break;
        }
        return;
      }

      this._destroyLatestEmbeddedViewRef();
      this._latestEmbeddedViewRef = undefined;
    }

    switch (current.status) {
      case LOADING: {
        this._createEmbeddedViewRef(
          this.loading || AsyncJobDirective.defaultLoading,
        );
        break;
      }

      case SUCCESS: {
        this._createEmbeddedViewRef(this._templateRef, current.data);
        break;
      }

      case EMPTY: {
        this._createEmbeddedViewRef(
          this.empty || AsyncJobDirective.defaultEmpty,
        );
        break;
      }

      case ERROR: {
        if (!this.error) throw current.error;
        this._createEmbeddedViewRef(
          this.error || AsyncJobDirective.defaultError,
          current.error,
        );
        break;
      }
    }
  }

  private _isNotUpdated(jobValue: AsyncJobValue) {
    return (
      jobValue.status === this._latestAsyncJobValue.status &&
      jobValue.data === this._latestAsyncJobValue.data &&
      jobValue.error === this._latestAsyncJobValue.error
    );
  }

  private _createEmbeddedViewRef(
    templateRef: TemplateRef<{ $implicit: T }>,
    context?: T,
  ) {
    if (this._latestEmbeddedViewRef && this._templateRef === templateRef) {
      this._latestEmbeddedViewRef.context.$implicit = context;
    } else {
      this._latestEmbeddedViewRef = this._viewContainerRef.createEmbeddedView(
        templateRef,
        { $implicit: context },
      );
    }
  }

  private _destroyLatestEmbeddedViewRef() {
    if (!this._latestEmbeddedViewRef) {
      return;
    }

    this._latestEmbeddedViewRef.destroy();
    this._latestEmbeddedViewRef = undefined;
  }
}

export class AsyncJobStatusDirective<T> {
  public readonly templateRef: TemplateRef<{ $implicit: T }> | undefined;
}

@Directive({ selector: '[asyncJobDefaultLoading]' })
export class AsyncJobDefaultLoadingDirective extends AsyncJobStatusDirective<any> {
  public constructor(public override readonly templateRef: TemplateRef<any>) {
    super();
    AsyncJobDirective.defaultLoading = this.templateRef;
    AsyncJobDirective.updateTemplateRef(LOADING);
  }
}

@Directive({ selector: '[asyncJobDefaultEmpty]' })
export class AsyncJobDefaultEmptyDirective extends AsyncJobStatusDirective<any> {
  public constructor(public override readonly templateRef: TemplateRef<any>) {
    super();
    AsyncJobDirective.defaultEmpty = this.templateRef;
    AsyncJobDirective.updateTemplateRef(EMPTY);
  }
}

@Directive({ selector: '[asyncJobDefaultError]' })
export class AsyncJobDefaultErrorDirective extends AsyncJobStatusDirective<any> {
  public constructor(public override readonly templateRef: TemplateRef<any>) {
    super();
    AsyncJobDirective.defaultError = this.templateRef;
    AsyncJobDirective.updateTemplateRef(ERROR);
  }
}

export class AsyncJobContext<T> {
  public constructor(public $implicit: T) {}
}
