import { LayoutModule } from '@angular/cdk/layout';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AsyncJobModule } from '@ay-gosu/ui/common/async-job';
import { AsyncJobStatusEnum } from '@ay-gosu/ui/common/async-job/async-job';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { version } from '../environments/version';
import { GosuIconComponent } from './components/gosu-icon/icon.component';
import { LogoComponent } from './components/gosu-logo';
import { IconComponent } from './components/icon';
import { PageLoadingComponent } from './components/page-loading';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TranslatePipe } from './pipe/translate.pipe';
import { TrustHtmlPipe } from './pipe/trust-html.pipe';
import { COPYRIGHT } from './provider';
import { ConnectionService } from './service/connection.service';
import { GoogleTagManagerService } from './service/google-tag-manager.service';
import { I18nService } from './service/i18n.service';
import { PageService } from './service/page.service';
import { ScreenService } from './service/screen.service';
import { TokenService } from './service/token.service';
import { getExpansionTrigger } from './trigger/expansion';
import { getSlideDownTrigger } from './trigger/slide';

@Component({
  selector: 'gosu-root',
  templateUrl: './app.component.html',
  animations: [getSlideDownTrigger(), getExpansionTrigger()],
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    PageLoadingComponent,
    LogoComponent,
    GosuIconComponent,
    IconComponent,
    ToolbarComponent,
    TrustHtmlPipe,
    TranslatePipe,
    AsyncJobModule,
    AsyncPipe,
    NgTemplateOutlet,
    LayoutModule,
    MatIconModule,
  ],
  host: {
    class: 'relative',
  },
})
export class AppComponent implements OnInit, OnDestroy {
  public backstageVersion = version;

  private readonly _destroy$ = new Subject<void>();

  public disableToolbarRouters = [
    'login',
    'market-place/bind',
    'embedded-private-message',
  ];

  public showGoBackRouters = ['private-message', 'edit-message'];

  public enableToolbar$ = this._router.events.pipe(
    startWith(null),
    takeUntil(this._destroy$),
    debounceTime(100),
    map(
      () =>
        this.disableToolbarRouters.find((rule) =>
          location.pathname.includes(rule),
        ) === undefined,
    ),
    shareReplay(1),
  );

  public enableGoBackButton$ = this._router.events.pipe(
    startWith(null),
    takeUntil(this._destroy$),
    debounceTime(100),
    map(() => {
      const route = this._router.routerState.snapshot;
      console.log(route);
      return (
        this.showGoBackRouters.find((rule) => route.url.includes(rule)) &&
        Object.keys(route.root.children[0].firstChild.params).length > 0
      );
    }),
    shareReplay(1),
  );

  public isConnecting$ = this._connectionService.status$.pipe(
    map((status) => !status),
    distinctUntilChanged(),
  );

  public isLogging$ = toObservable(this._tokenService.payload$.status).pipe(
    map((status) => status === AsyncJobStatusEnum.LOADING),
  );

  @ViewChild('drawer', { static: false })
  public drawer: MatDrawer;

  public closeDrawerWhenLogout = this._tokenService.rxAccount$
    .pipe(
      filter((token) => token === null),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => {
      if (!this.drawer) return;
      this.drawer.close();
    });

  public routerOutletContainerHeight = signal('100%');

  public isMobile$ = this._screenService.isMobile$;

  public constructor(
    private readonly _router: Router,
    private readonly _connectionService: ConnectionService,
    private readonly _tokenService: TokenService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    public readonly pageService: PageService,
    @Inject(COPYRIGHT)
    public readonly copyright: string,
    private readonly _i18nService: I18nService,
    private readonly _screenService: ScreenService,
  ) {
    this.enableToolbar$.pipe(takeUntil(this._destroy$)).subscribe((enable) => {
      this.routerOutletContainerHeight.set(
        enable ? 'calc(100% - 64px)' : '100%',
      );
    });
  }

  public ngOnInit(): void {
    this._googleTagManagerService.init();
    this._i18nService.init();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public async logout() {
    this._tokenService.logout();
    this.drawer.close();
  }

  public back() {
    const currentUrl = this._router.routerState.snapshot.url;
    const parentUrl =
      currentUrl.substring(0, currentUrl.lastIndexOf('/')) || '/';

    this._router.navigate([parentUrl]);
  }
}
