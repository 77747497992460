import { Pipe, PipeTransform } from '@angular/core';

// WebChat 支援部分的 Markdown 語法，所以需要將部分 Markdown 語法轉換成 HTML 語法
@Pipe({
  name: 'formatTextMessage',
  pure: true,
  standalone: true,
})
export class FormatTextMessagePipe implements PipeTransform {
  // 為了避免在多次轉換下，連結中的連結被轉換成 HTML 的 A(<a href="<a href="https://www.google.com">">Google</a>), 所以先把連結轉換成 alt#1, alt#2, alt#3, ...
  // 再把 alt#1, alt#2, alt#3, ... 轉換成 HTML 的 A
  public transform(value: string): string {
    if (!value) {
      return value;
    }

    const links: Link[] = [];
    value = this._replaceMarkdownLinksToAlt(value, links);
    value = this._replaceLinksToAlt(value, links);
    value = this._replaceAlt(value, links);
    return value;
  }

  private _replaceLinksToAlt(str: string, links: Link[]) {
    const matches = str.match(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
    );

    if (!matches) {
      return str;
    }

    matches.map((matchStr) => {
      const alt = `alt#${links.length + 1}`;
      const content = `<a href="${matchStr}" target="_blank">${matchStr}</a>`;
      links.push({ alt, content });
      str = str.replace(matchStr, alt);
    });

    return str;
  }

  private _replaceMarkdownLinksToAlt(str: string, links: Link[]) {
    let matches = str.match(/\[([^\[\]]*)\]\((.*?)\)/gim);
    if (!matches) {
      return str;
    }

    const splitMatch = /\[([^\[]+)\]\((.*)\)/;
    matches.map((matchStr) => {
      let splitContent = splitMatch.exec(matchStr);
      let [full, text, url] = splitContent;
      if (!text || !url) return;
      const alt = `alt#${links.length + 1}`;
      let content = '';
      //如果開頭是#則是發訊息至機器人，否則是連結
      if (url[0] === '#') {
        content = `<span alt='${url.substring(1)}'>${text}</span>`;
      } else {
        content = `<a href="${url}" target="_blank">${text}</a>`;
      }
      links.push({ alt, content });
      str = str.replace(full, alt);
    });

    return str;
  }

  private _replaceAlt(str: string, links: Link[]) {
    links.forEach(({ alt, content }) => (str = str.replace(alt, content)));
    return str;
  }
}

export type Link = {
  alt: string;
  content: string;
};
